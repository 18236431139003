<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" class="demo-form-inline form_row" size="small" label-width="200px">
                <el-form-item label="设备" prop="JCDID">
                    <el-select v-model="formdata.saveJson.JCDID" placeholder="请选择设备" :disabled="true">
                        <el-option v-for="(item,index) in sbList" :key="`${item.ID}-${index}`" :label="item.SBMC" :value="item.ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间" prop="JCSJ">
                    <el-date-picker v-model="formdata.saveJson.JCSJ" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :disabled="!isEdit" placeholder="请选择时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="风速" prop="FS">
                    <el-input v-model="formdata.saveJson.FS" placeholder="请输入风速" :maxlength="50" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append">m/s</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from '../../../edit-button.js';
import infoMixin from '../../../info_mixin.js';
import { mapActions } from 'vuex';
export default {
    name: "equipmentdata_windspeed_form",
    components: {
    },
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: '',
            formdata: {
                saveJson: {
                    JCDID: '',
                    JCSJ: '',
                    FS: 0,
                },
                heritageId: '',
                itemID: '8020511',
                userName: '',
                userId: ''
            },
            sbList: [], // 设备列表
            itemID: '8020511',
        };
    },
    computed: {
    },
    watch: {
        'formdata.saveJson.cnsxlylx': function() {
        },
    },
    mounted() {
        this.getSbList();
    },
    methods: {
        ...mapActions([
            'getSelectData',
        ]),
        async getSbList() {
            this.sbList = [];
            let searchList = [];
            searchList = [{
                "FieldName": "SBLX",
                "FieldValue": "5",
                "OperatorChar": "="
            }];
            let jsonStr = JSON.stringify(searchList);
            let res = await this.getSelectData({
                _cols: [],	//字段集
                _keyword: null,	//查询关键字
                _serarchCols: null,	//关键字字段集合
                _conditionList: jsonStr,	//过滤条件集合
                _dicOrderby: {},	
                _pageSize: null,	//每页最大记录数
                _pageIndex: null,	//页索引
                _returnSum: false,	//是否返回记录总数
                _needVersion: false,	//是否分版本查询
                _searchFormal: false,    //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: "116", 	//业务编号
            })
            this.sbList = res.data;
        },
    }
};
</script>